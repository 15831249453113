export default function ARecipeWorkshop() {
    return (
        <>
            <p>
                The by-invite-only workshop aims to explore Singapore’s rich
                culinary landscape. Each guest is encouraged to bring an object
                holding significant food-related memories or stories, which will
                serve as the “raw material” for collaboratively crafting an
                Appetising, Appealing and Accessible recipe.
            </p>
            <p>
                The goal is to develop 10 speculative and conceptual recipes
                across both workshops, which will be “baked” into 3D sculptures
                and presented at DESIGN.
            </p>
        </>
    );
}
